import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../utils/normalize.css"
import "../utils/css/screen.css"
import Title from "../components/title/subtitle"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const Profile = ({ data }) => {
  useEffect(() => {
    gsap.fromTo(".page-head", { y: 20, opacity: 0 }, { y: 0, opacity: 1 })
    gsap.fromTo(
      ".profile",
      { y: 20, opacity: 0 },
      { y: 0, opacity: 1, delay: 0.5 }
    )
    gsap.fromTo(
      ".kg-card",
      { y: 20, opacity: 0 },
      { y: 0, opacity: 1, delay: 1 }
    )
  }, [])

  const post = data.contentfulProfile

  return (
    <Layout>
      <SEO
        title="プロフィール"
        description="しょうのまきのプロフィールはこちらから。東京でイラストデザイン制作やロゴデザイン、チラシ制作、フライヤー制作、パンフレットの制作、カード類の制作などの広告印刷物の制作などおこなっております。"
        keywords={[
          `イラスト制作`,
          `美容室の広告印刷物の制作`,
          `ロゴデザイン`,
          `チラシ制作`,
        ]}
      />
      <header className="page-head">
        <Title title={post.title} />
      </header>

      <article className="post-content post-content-width">
        <div className="post-content-body profile">
          <div
            dangerouslySetInnerHTML={{
              __html: post.body ? post.body.childContentfulRichText.html : null,
            }}
          />

          <figure className="kg-card kg-image-card ">
            {post.image ? (
              <Img
                className="kg-image"
                fluid={post.image ? post.image.fluid : null}
                alt={post.title}
                placeholderStyle={{ backgroundColor: "rgb(248, 248, 248)" }}
              />
            ) : null}
          </figure>
        </div>
      </article>
    </Layout>
  )
}
export default Profile

export const pageQuery = graphql`
  {
    contentfulProfile {
      title
      body {
        childContentfulRichText {
          html
        }
      }
      image {
        fluid(
          resizingBehavior: SCALE
          maxWidth: 2000
          maxHeight: 1300
          quality: 85
        ) {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
    }
  }
`
